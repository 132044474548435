import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { FeedbackContext } from "../../contexts/FeedbackContext";
import { Box, Button, Container } from "@mui/material";
import { Text } from "../../components/core";
import { TransportTable } from "../../components/patterns/Transport/TransportTable";
import { TransportForm } from "../../components/patterns/Transport/TransportForm";
import { createTransport, getAllTransports, removeTransport, updateTransport } from "../../services/transportService";
import { formatValue } from "../../utils/formatCurrency";
import { ConfirmationDialog } from "../../components/patterns/Dialogs/ConfirmationDialog";

const initialValues = {
  title: "",
  frequency: [],
  thumbnail: "",
  departureTime: "",
  returnTime: "",
  pricesTable: {
    minPrice: "",
    defaultPrice: "",
  },
};

export const Transport = () => {
  const { setResponse } = useContext(FeedbackContext);
  const { setLoading } = useContext(AppContext);

  const [transportList, setTransportList] = useState([]);
  const [transport, setTransport] = useState(initialValues);
  // console.log("🚀 ~ file: Transport.js:53 ~ Transport ~ transport:", transport);
  const [openTransportDialog, setOpenTransportDialog] = useState(false);
  const [showDialogConfirmation, setShowDialogConfirmation] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
    item: {},
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAllTransports();
      setTransportList(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTransport((prevTransport) => {
      const transportCopy = { ...prevTransport };
      const nestedProps = name.split('.');

      if (nestedProps.length > 1) {
        transportCopy[nestedProps[0]][nestedProps[1]] = formatValue(value);
      } else {
        transportCopy[name] = value;
      }

      return transportCopy;
    });
  };

  const handleUpload = (data) => {
    const { url } = data;
    setTransport({ ...transport, thumbnail: url });
  };

  const handleDeleteTempThumb = () =>
    setTransport({ ...transport, thumbnail: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const newTransport = await createTransport(transport);
      console.log("Transport created:", newTransport);
      setTransport(initialValues);
      fetchData();
      setResponse({
        open: true,
        message: "Transporte criado com sucesso!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setResponse({
        open: true,
        message: "Ocorreu um erro ao criar o transporte",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setOpenTransportDialog(false);
    }
  };

  const handleEditSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await updateTransport(transport);
      console.log(
        "🚀 ~ file: Transport.js:112 ~ handleEditSubmit ~ response:",
        response
      );
      setTransport(initialValues);
      fetchData();
      setResponse({
        open: true,
        message: "Transporte atualizado com sucesso!",
        severity: "success",
      });
    } catch (error) {
      setResponse({
        open: true,
        message: "Ocorreu um erro ao atualizar o transporte",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setOpenTransportDialog(false);
    }
  };

  const handleEdit = (transport) => {
    setTransport(transport);
    setOpenTransportDialog(true);
  };

  const handleDelete = (transport) => {
    setShowDialogConfirmation({
      open: true,
      title: "Excluir transporte",
      message: `Deseja excluir o transporte ${transport.title}?`,
      onConfirm: () => handleDeleteConfirm(transport),
    });
  };

  const handleDeleteConfirm = async (transport) => {
    setLoading(true);
    try {
      await removeTransport(transport._id);
      fetchData();
      setResponse({
        open: true,
        message: "Transporte excluído com sucesso!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setResponse({
        open: true,
        message: "Ocorreu um erro ao excluir o transporte",
        severity: "error",
      });
    } finally {
      setLoading(false);
      setShowDialogConfirmation(false);
    }
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        sx={{ padding: 2, display: "flex", justifyContent: "space-between" }}
      >
        <Text variant="h5">Transportes</Text>
        <Text color="red" variant="body2" sx={{ fontWeight: 600 }}>
        Atenção, esta página será descontinuada em breve. Utilize a categoria correta na página de Produtos
        </Text>
        <Button
          onClick={() => setOpenTransportDialog(true)}
          variant="contained"
          color="primary"
          disabled
        >
          Novo transporte
        </Button>
      </Box>
      <TransportTable
      data={transportList}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      />
      <TransportForm
        initialValues={transport}
        open={openTransportDialog}
        onClose={() => setOpenTransportDialog(false)}
        handleChange={handleChange}
        handleDeleteThumb={handleDeleteTempThumb}
        handleUpload={handleUpload}
        handleSubmit={handleSubmit}
        handleEditSubmit={handleEditSubmit}
      />
      {showDialogConfirmation && (
        <ConfirmationDialog
          open={showDialogConfirmation.open}
          dialogTitle={showDialogConfirmation.title}
          dialogMessage={showDialogConfirmation.message}
          handleClose={() => setShowDialogConfirmation(false)}
          onConfirm={showDialogConfirmation.onConfirm}
        />
      )}
    </Container>
  );
};


