import React from 'react';
import { List, ListItemButton, ListItemText, Drawer, ListItem, Collapse, Chip, Typography, Icon } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import routesMenu from './routesMenu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import HotelIcon from '@mui/icons-material/Hotel';

export const DrawerMenu = ({ open, onClose, containerWidth }) => {
  const location = useLocation();

  const [expand, setExpand] = React.useState(false);
  const [expandProductTab, setExpandProductTab] = React.useState(false);

  return (
    <Drawer open={open} onClose={onClose}>
    <Typography fontWeight={600}>

      <List style={{ width: containerWidth, marginTop: 60 }}>
        {routesMenu.filter(item => !item.path.includes('pack')).map((route, index) => (
          <MenuItem to={route.path} key={`${route.name}-${index}`} selected={route.path === location.pathname}>
          <Typography
              fontSize={14}
              fontWeight={600}
              >
                {route.name}
              </Typography>
          </MenuItem>
        ))}
        <ListItem onClick={() => setExpand(!expand)}>
          <ListItemText primary="Pacotes" primaryTypographyProps={{ fontSize: 14, fontWeight: 600 }} />
          {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {routesMenu.filter(item => item.path.includes('pack')).map((route, index) => (
              <MenuItem to={route.path} key={`${route.name}-${index}`} selected={route.path === location.pathname}>
              <Typography
              fontSize={14}
              fontWeight={500}
              >
                {route.name}
              </Typography>
              </MenuItem>
            ))}

          </List>
        </Collapse>
        <ListItem onClick={() => setExpandProductTab(!expandProductTab)}>
          <ListItemText primary="Produtos" primaryTypographyProps={{ fontSize: 14, fontWeight: 600 }} />
          {expandProductTab ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={expandProductTab} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <MenuItem to='/produtos'>
        <Typography
        fontWeight={500}
        fontSize={14}
        >
              Produtos
        </Typography>
            </MenuItem>
            <MenuItem to='/categories'>
            <Typography
        fontWeight={500}
        fontSize={14}
        >
              Categorias
        </Typography>
            </MenuItem>
          </List>
        </Collapse>
        <MenuItem
          to="/hotels"
          selected={location.pathname === '/hotels'}
        >
        <Typography
        fontWeight={600}
        fontSize={14}
        >
          Hoteis
        </Typography>
        </MenuItem>
        <MenuItem
          to="/orchestrator"
          selected={location.pathname === '/orchestrator'}
        >
        <Typography
        fontWeight={600}
        fontSize={14}>
        <Icon>
          <AutoFixHighIcon />
        </Icon>
          Orquestrador
        </Typography>
        </MenuItem>
        
      </List>
    </Typography>
    </Drawer>
  );
};

const MenuItem = ({ children, to, selected }) => {
  return (
    <Link to={to} style={styles.link}>
      <ListItemButton selected={selected}>
        <ListItemText primary={children} primaryTypographyProps={styles.text} />
      </ListItemButton>
    </Link>
  );
};

const styles = {
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  text: {
    fontSize: 14,
  },
};
